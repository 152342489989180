<template>
    <page class="hello">
        <!--<div v-power="['user:add:user:add:user:add:user:add:user:add']">['user:add']</div>-->
        <!--<div v-power="['menu:add','user:add']">['menu:add','user:add']</div>-->
        <!--<div v-power.&&="['menu:add','user:add']">['menu:add','user:add']&&</div>-->
        <!--<div v-power.&&="['menu:add','region:add']">['menu:add','region:add']&&</div>-->
        <div slot="body">
            <!--            <div class='i18n'>
                            <div>i18n 默认本地化 en</div>
                            <div>key:{{ $t('pageKey','默认值') }}</div>
                            <div>key1 指定为 zh:{{ $t('pageKey1','默认值','zh') }}</div>
                            <div>key2:{{ $t('pageKey2') }}</div>
                            <el-button @click='$i18n.locale = $i18n.locale=== "zh"?"en":"zh";'>{{ $i18n.locale }}</el-button>
                        </div>-->
            <el-card
                v-if="$power(['index:todoList'])"
                class="ma-b"
                size="small"
                shadow="never"
                :header="$t('pageKey869', '待办事项')"
            >
                <el-row
                    type="flex"
                    justify="space-between"
                >
                    <el-col
                        :span="4"
                        v-if="$power(['index:expressOrder'])">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey870', '待发货订单') }}
                                </div>
                                <Linker
                                    to="/order/order/order?orderStatus=20"
                                    class="value"
                                >
                                    {{ orderNumMap.waitShip1 }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col
                        :span="4"
                        v-if="$power(['index:expressOrder'])">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey871', '仓库中订单') }}
                                </div>
                                <Linker
                                    to="/order/order/order?orderStatus=30-1"
                                    class="value"
                                >
                                    {{ orderNumMap.inStock1 }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col
                        :span="4"
                        v-if="$power(['index:platOrder'])">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey872', '待发货平台订单') }}
                                </div>
                                <Linker
                                    to="/order/order/saleOrder?orderStatus=20"
                                    class="value"
                                >
                                    {{ orderNumMap.waitShip2 }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col
                        :span="4"
                        v-if="$power(['index:platOrder'])">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey873', '仓库中平台订单') }}
                                </div>
                                <Linker
                                    to="/order/order/saleOrder?orderStatus=30-1"
                                    class="value"
                                >
                                    {{ orderNumMap.inStock2 }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col
                        :span="4"
                        v-if="$power(['index:bondedOrder'])">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey874', '待发货保税订单') }}
                                </div>
                                <Linker
                                    to="/order/order/bondedOrder?orderStatus=20"
                                    class="value"
                                >
                                    {{ orderNumMap.waitShip3 }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col
                        :span="4"
                        v-if="$power(['index:bondedOrder'])">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey875', '仓库中保税订单') }}
                                </div>
                                <Linker
                                    to="/order/order/bondedOrder?orderStatus=30-1"
                                    class="value"
                                >
                                    {{ orderNumMap.inStock3 }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col
                        :span="4"
                        v-if="$power(['index:directMailOrder'])">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey874', '待发货直邮订单') }}
                                </div>
                                <Linker
                                    to="/order/order/directMailOrder?orderStatus=20"
                                    class="value"
                                >
                                    {{ orderNumMap.waitShip6 }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col
                        :span="4"
                        v-if="$power(['index:directMailOrder'])">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey875', '仓库中直邮订单') }}
                                </div>
                                <Linker
                                    to="/order/order/directMailOrder?orderStatus=30-1"
                                    class="value"
                                >
                                    {{ orderNumMap.inStock6 }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col
                        :span="4"
                        v-if="$power(['index:communityOrder'])">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey876', '待发货配送订单') }}
                                </div>
                                <Linker
                                    to="/order/order/communityOrder?orderStatus=20"
                                    class="value"
                                >
                                    {{ orderNumMap.waitShip4 }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col
                        :span="4"
                        v-if="$power(['index:communityOrder'])">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey877', '仓库中配送订单') }}
                                </div>
                                <Linker
                                    to="/order/order/communityOrder?orderStatus=orderDelivery-30"
                                    class="value"
                                >
                                    {{ orderNumMap.inStock4 }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col
                        :span="4"
                        v-if="$power(['index:storeDistributionOrder'])">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey876', '待发货配送订单') }}
                                </div>
                                <Linker
                                    to="/order/order/storeDistributionOrder?orderStatus=20"
                                    class="value"
                                >
                                    {{ orderNumMap.waitShip5 }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col
                        :span="4"
                        v-if="$power(['index:storeDistributionOrder'])">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey877', '仓库中配送订单') }}
                                </div>
                                <Linker
                                    to="/order/order/storeDistributionOrder?orderStatus=orderDelivery-30"
                                    class="value"
                                >
                                    {{ orderNumMap.inStock5 }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey878', '退款订单审核') }}
                                </div>
                                <Linker
                                    to="/order/afterSale/orderRefund"
                                    class="value"
                                >
                                    {{ orderNumMap.refundWaitReview }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey879', '退货退款订单审核') }}
                                </div>
                                <Linker
                                    to="/order/afterSale/orderReturn"
                                    class="value"
                                >
                                    {{ orderNumMap.returnWaitReview }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey880', '退货退款确认收货') }}
                                </div>
                                <Linker
                                    to="/order/afterSale/orderReturn?refundStatus=7"
                                    class="value"
                                >
                                    {{ orderNumMap.returnWaitReceive }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                v-if="$power(['index:orderStat'])"
                class="ma-b"
                size="small"
                shadow="never"
                :header="$t('pageKey883', '销量情况')"
            >
                <el-row>
                    <el-col :span="24">
                        <el-radio-group
                            v-model="goodsSendMode"
                            @change="changeGoodsSendMode"
                            size="medium">
                            <el-radio-button :label="0">全部</el-radio-button>
                            <el-radio-button
                                v-for="item in goodsSendModeList"
                                :key="item.id"
                                :label="item.id">{{item.name}}</el-radio-button>
                        </el-radio-group>
                    </el-col>
                    <el-col :span="12">
                        <div class="info-item ma-t">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey881', '订单数量') }}
                                </div>
                                <Linker
                                    :to="'/stat/payStat/payStat?statDateDay=' + statDate + '&statDateDay=' + statDate"
                                    class="value"
                                >
                                    {{ orderStatMap.orderNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey882', '销售金额') }}
                                </div>
                                <Linker
                                    :to="'/stat/payStat/payStat?statDateDay=' + statDate + '&statDateDay=' + statDate"
                                    class="value"
                                >
                                    {{ orderStatMap.payableAmount }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="ma-t">
                    <el-col :span="24">
                        <ECharts
                            class="echarts-demo"
                            :options="orderStatOptions"
                        />
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                v-if="$power(['index:buyerStat'])"
                class="ma-b"
                size="small"
                shadow="never"
                :header="$t('pageKey884', '会员概况')"
            >
                <el-row>
                    <el-col :span="6">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey885', '会员总数') }}
                                </div>
                                <Linker
                                    :to="'/mb/mbmg/buyer'"
                                    class="value"
                                >
                                    {{ buyerStatMap.buyerNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey886', '新增会员数') }}
                                </div>
                                <Linker
                                    :to="'/mb/mbmg/buyer?insertTime=' + statDate + '&insertTime=' + statDate"
                                    class="value"
                                >
                                    {{ buyerStatMap.buyerNumND }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey887', '代理商总数') }}
                                </div>
                                <Linker
                                    :to="'/dt/dtmg/distributor?isDistributor=1'"
                                    class="value"
                                >
                                    {{ buyerStatMap.distributorNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    {{ $t('pageKey888', '新增代理商数') }}
                                </div>
                                <Linker
                                    :to="'/dt/dtmg/distributor?isDistributor=1&joinTime=' + statDate + '&joinTime=' + statDate"
                                    class="value"
                                >
                                    {{ buyerStatMap.distributorNumND }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="ma-t">
                    <el-col :span="24">
                        <ECharts
                            class="echarts-demo"
                            :options="buyerStatOptions"
                        />
                    </el-col>
                </el-row>
            </el-card>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'Index',
    mixins: [pagesMixin],
    data() {
        return {
            orderNumMap: {},
            orderStatMap: {},
            buyerStatMap: {},
            orderStatList: [],
            buyerStatList: [],
            statDate: '',
            goodsSendModeList: [],
            goodsSendMode: '0',
        };
    },
    computed: {
        orderStatOptions() {
            const orderNumData = [];
            const payableAmountData = [];
            const dayData = [];
            this.orderStatList.forEach(item => {
                orderNumData.push(item.orderNum);
                payableAmountData.push(item.payableAmount);
                dayData.push(item.statDate);
            });
            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true,
                },
                toolbox: {
                    feature: {
                        // dataView: { show: true, readOnly: false },
                        magicType: {
                            show: true,
                            type: ['line', 'bar'],
                            title: {
                                line: this.$t('pageKey890', '切换为折线图'),
                                bar: this.$t('pageKey891', '切换为柱状图'),
                            },
                        },
                        restore: { show: true, title: this.$t('pageKey892', '还原') },
                        saveAsImage: { show: true, title: this.$t('pageKey893', '保存为图') },
                    },
                },
                legend: {
                    data: [this.$t('pageKey881', '订单数量'), this.$t('pageKey882', '销售金额')],
                },
                xAxis: [
                    {
                        type: 'category',
                        data: dayData,
                        axisPointer: {
                            type: 'shadow',
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: this.$t('pageKey292', '数量'),
                        // min: 0,
                        // max: 250,
                        // interval: 50,
                        axisLabel: {
                            formatter: '{value}',
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        type: 'value',
                        name: this.$t('pageKey889', '金额'),
                        // min: 0,
                        // max: 25,
                        // interval: 5,
                        axisLabel: {
                            formatter: '{value}',
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: this.$t('pageKey881', '订单数量'),
                        type: 'bar',
                        data: orderNumData,
                    },
                    {
                        name: this.$t('pageKey882', '销售金额'),
                        type: 'line',
                        yAxisIndex: 1,
                        data: payableAmountData,
                    },
                ],
            };
        },
        buyerStatOptions() {
            const buyerNumData = [];
            const distributorNumData = [];
            const dayData = [];
            this.buyerStatList.forEach(item => {
                buyerNumData.push(item.buyerNum);
                distributorNumData.push(item.distributorNum);
                dayData.push(item.statDate);
            });
            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true,
                },
                toolbox: {
                    feature: {
                        // dataView: { show: true, readOnly: false },
                        magicType: {
                            show: true,
                            type: ['line', 'bar'],
                            title: {
                                line: this.$t('pageKey890', '切换为折线图'),
                                bar: this.$t('pageKey891', '切换为柱状图'),
                            },
                        },
                        restore: { show: true, title: this.$t('pageKey892', '还原') },
                        saveAsImage: { show: true, title: this.$t('pageKey893', '保存为图') },
                    },
                },
                legend: {
                    data: [this.$t('pageKey894', '会员增长数'), this.$t('pageKey895', '代理商增长数')],
                },
                xAxis: [
                    {
                        type: 'category',
                        data: dayData,
                        axisPointer: {
                            type: 'shadow',
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: this.$t('pageKey896', '人数'),
                        // min: 0,
                        // max: 250,
                        interval: 50,
                        axisLabel: {
                            formatter: '{value}',
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: this.$t('pageKey894', '会员增长数'),
                        type: 'bar',
                        data: buyerNumData,
                    },
                    {
                        name: this.$t('pageKey895', '代理商增长数'),
                        type: 'bar',
                        // yAxisIndex: 1,
                        data: distributorNumData,
                    },
                ],
            };
        },
    },
    methods: {
        todoList() {
            return this.$api.Rs.Console.todoList().then(json => {
                const res = json.data.data;
                this.orderNumMap = res.orderNumMap;
                this.statDate = res.statDate;
            });
        },
        orderStat() {
            return this.$api.Rs.Console.orderStat({ goodsSendMode: this.goodsSendMode }).then(json => {
                const res = json.data.data;
                this.orderStatMap = res.orderStatMap;
                this.orderStatList = res.orderStatList;
            });
        },
        buyerStat() {
            return this.$api.Rs.Console.buyerStat().then(json => {
                const res = json.data.data;
                this.buyerStatMap = res.buyerStatMap;
                this.buyerStatList = res.buyerStatList;
            });
        },
        initGoodsSendModeList() {
            this.$api.Sp.Shop.getShopGoodsSendModeList({}).then(json => {
                this.goodsSendModeList = json.data.data;
            });
        },
        changeGoodsSendMode() {
            return this.orderStat();
        },
    },
    created() {
        this.initGoodsSendModeList();
        this.todoList();
        this.buyerStat();
        return this.orderStat();
    },
};
</script>

<style lang="scss" scoped>
.echarts-demo {
    width: 100%;
}
</style>
<style lang="scss">
.info-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > .icon {
        flex: 0 0 auto;
        font-size: 26px;
        width: 30px;
        text-align: center;
        margin-right: $margin;
    }

    > .body {
        flex: 1 1 1%;
        text-align: center;

        > .label {
        }

        > .value {
            font-weight: bold;
            font-size: 150%;
            color: $color-primary;
        }
    }
}
</style>
